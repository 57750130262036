import { ComponentTracker, IComponentTracker } from './ComponentTracker';
import { CoreTracker, ICoreTracker } from './CoreTracker';
import { LivesportTracker, ILivesportTracker } from './LivesportTracker';
import { IVideoTracker, VideoTracker } from './VideoTracker';
import { Logger } from './helpers/logger';
import { SnowplowTrackingOptions } from './types';

export interface ISnowplowTracker {
  /**
   * Core tracker that includes all core snowplow tracking functionalities.
   */
  coreTracker: ICoreTracker;
  /**
   * Video tracker that includes all video tracking functionalities.
   */
  videoTracker: IVideoTracker;
  /**
   * Livesport tracker that includes all livesport tracking functionalities.
   */
  livesportTracker: ILivesportTracker;
  /**
   * Component tracker
   */
  componentTracker: IComponentTracker;
}

/**
 * Tracker class that consolidates core and video tracking functionalities.
 * This class ensures that all tracking related actions are managed through a single interface,
 * enhancing maintainability and scalability.
 */
export class SnowplowTracker implements ISnowplowTracker {
  public coreTracker: ICoreTracker;
  public videoTracker: IVideoTracker;
  public livesportTracker: ILivesportTracker;
  public componentTracker: IComponentTracker;

  constructor(options: SnowplowTrackingOptions = {}) {
    const { logLevel = 'WARN' } = options;
    Logger.setLogLevel(logLevel);
    this.coreTracker = new CoreTracker(options);
    this.videoTracker = new VideoTracker();
    this.livesportTracker = new LivesportTracker();
    this.componentTracker = new ComponentTracker();
  }
}