
import packageJson from '../../../package.json';

import { Tv2NoVideoEvent, Tv2NoVideoPlayer } from '../../snowtype/snowplow';
import { Asset } from '../types';

const playerVersion = packageJson.version;

export const buildVideoEventInfoContext = (asset: Asset): Tv2NoVideoEvent => ({
  videoCategory: asset.category,
  categoryTypeOfAction: asset.categoryTypeOfAction,
  videoLive: false,
  videoName: asset.title,
  videoProgramId: asset.id,
  videoTags: '',
  publishedDate: asset.publishedDate,
});

export const buildVideoPlayerInfoContext = (): Tv2NoVideoPlayer & Record<string, unknown> => ({
  videoPlayerName: 'TV 2 Klipp',
  videoPlayerVersion: playerVersion,
});