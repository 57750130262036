import { getPath } from 'base/utils'

const WebViewContainer = ({ assetId }: { assetId: string }) => {
  const openApp = () => {
    if (
      navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 ||
      navigator.userAgent.toLowerCase().indexOf('ipad') !== -1 ||
      navigator.userAgent.toLowerCase().indexOf('ipod') !== -1
    ) {
      window.open(`tv2sumo://open/${assetId}`)
    } else {
      window.open(`https://play.tv2.no/sport/${getPath()}?progId=${assetId}`)
    }
  }
  return (
    <div className="play-embed">
      <div className="play-embed__container">
        <div className="play-embed__view">
          <h2 className="play-embed__text">
            Dette innholdet må sees i <span style={{ whiteSpace: 'pre' }}>TV 2 Play</span>
          </h2>
          <div className="play-embed__openPlay" onClick={openApp} >Åpne videoen i TV 2 Play</div>
        </div>
      </div>
    </div>)
}

export default WebViewContainer
