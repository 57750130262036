import { useEffect } from 'react'
import { Environment, SnowplowTracker } from '@tv2no/tv2no-snowplow-tracking'
import config from 'config'
import { AnalyticsEventsEnum } from 'tv2-web-player/dist/lib/enums'

const useSnowplowTracking = (player, data) => {

  const snowplowTracker = new SnowplowTracker({
    appId: 'tv2no-web-player',
    environment: config.environment as Environment || 'development'
  })
  
  useEffect(() => {
    snowplowTracker.coreTracker.initializeTracker()
  }, [])

  useEffect(() => {
    const handlePlayerStateChange = (analyticsEventType: AnalyticsEventsEnum) => {
      const trackingData = data?.playback?.trackingMetadata

      if (!trackingData) {
        return
      }

      const assetData = {
        id: trackingData.videoId,
        category: trackingData.categoryName,
        categoryId: trackingData.categoryId,
        videoLive: trackingData.streamType == 'LIVE',
        title: trackingData.title,
        duration: trackingData.duration,
      }

      snowplowTracker.videoTracker.setAsset(assetData)

      switch (analyticsEventType) {
      case 'videoStart':
        snowplowTracker.videoTracker.videoStart({
          assetId: trackingData.videoId,
          assetName: trackingData.title,
          categoryTypeOfAction: trackingData.categoryName,
          numberOfVideosWatchedInSession: 0
        })
        break
      case 'videoPause':
        snowplowTracker.videoTracker.videoInteraction({
          assetId: trackingData.videoId,
          assetName: trackingData.title,
          categoryTypeOfAction: trackingData.categoryName,
          interactionType: 'pause'
        })
        break
      case 'videoResume':
        snowplowTracker.videoTracker.videoInteraction({
          assetId: trackingData.videoId,
          assetName: trackingData.title,
          categoryTypeOfAction: trackingData.categoryName,
          interactionType: 'resume'
        })
        break
      case 'videoComplete':
        snowplowTracker.videoTracker.videoEnd()
        break
      default:
        break
      }
    }

    if (player && typeof player === 'object' && player.analyticsEvent) {
      handlePlayerStateChange(player.analyticsEvent.type)
    }
  }, [player.analyticsEvent, data])

  return { snowplowTracker }
}

export default useSnowplowTracking
