import React from 'react'
import { getPath } from 'base/utils'

const Login = ({ onLogin }: { onLogin: () => void }) => {
  return (
    <button className="btn-login">Har du allerede en TV 2-bruker? &nbsp;
      <span onClick={onLogin} className="btn-login__text">Logg inn her</span>
    </button>
  )
}

const NewUser = ({url}: {url: string}) => <a href={url} target="_blank" className="play-embed__btn" rel="noreferrer">Kjøp abonnement</a>

const LoginContainer = ({ assetId, bearerToken }: { assetId: string, bearerToken?: string }) => {

  const [newUserUrl, setnewUserUrl] = React.useState('')

  React.useEffect(() => {
    const currentUrl = window.location.origin + window.location.pathname
    setnewUserUrl(`https://play.tv2.no/pakker/?referrer=tv2no&amp;backUrl=${currentUrl}&amp;assetId=${assetId}`)
  }, [])

  const login = async () => {
    try {
      if (window.TV2) {
        await window.TV2.login('Watch Play content', getPath())
      }
    } catch (e) { console.error(e) }
  }

  return (
    <div className="play-embed">
      <div className="play-embed__container">
        <div className="play-embed__view">
          <h2 className="play-embed__text">
					Du må abonnere på <span style={{whiteSpace: 'pre'}}>TV 2 Play</span> for å se dette innholdet		
          </h2>
          <div className="play-embed__login">
            <NewUser url={newUserUrl} />
            {!bearerToken && <Login onLogin={login} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginContainer
