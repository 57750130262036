export const isInWebView = () =>
  window.location.href.indexOf('mode=sportenapp') !== -1 ||
    window.location.href.indexOf('app=sporten') !== -1 ||
    (window.location.href.indexOf('mode=app') !== -1 &&
        (navigator.userAgent.toLowerCase().indexOf('android') > -1 ||
            navigator.userAgent.toLowerCase().indexOf('ipad') !== -1 ||
            navigator.userAgent.toLowerCase().indexOf('ipod') !== -1 ||
            navigator.userAgent.toLowerCase().indexOf('iphone') !== -1))

export const getPath = () => {
  const currentUrl = window.location.href
  switch (true) {
  case currentUrl.includes('fotball'):
    return 'fotball'
  case currentUrl.includes('handball'):
    return 'haandball'
  case currentUrl.includes('sykkel'):
    return 'sykkel'
  case currentUrl.includes('sjakk'):
    return 'sjakk'
  case currentUrl.includes('vintersport'):
    return 'vintersport'
  case currentUrl.includes('ishockey'):
    return 'ishockey'
  case currentUrl.includes('esport'):
    return 'e-sport'
  case currentUrl.includes('basketball'):
    return 'basketball'
  case currentUrl.includes('www.tv2.no/live'):
    return 'live sport front'
  default:
    return currentUrl
  }
}

export function getHtmlElement(mainContainer: HTMLElement | string | undefined): HTMLElement {
  if (!mainContainer) {
    throw new Error(`
      Cannot mount video player in the provided mainContainer \n` +
        `Provided mainContainer is: ${mainContainer} \n` +
        'You are probably missing \n' +
        '<div id="mainContainer" /> in your HTML Document? '
    )
  }

  if (typeof mainContainer === 'string') {
    return document.getElementById(mainContainer)
  }

  return mainContainer
}

export async function getLoginDetails(): Promise<{viewerId: string, bearerToken: string}> {
  try {
    let viewerId, bearerToken = null

    if (window.TV2?.getLoggedInUser && window.TV2?.getToken) {
      const user = await window.TV2.getLoggedInUser()
      viewerId = user?.id

      const token = await window.TV2.getToken()
      bearerToken = `Bearer ${token}`
    }

    return { viewerId, bearerToken }
  } catch (e) {
    console.error({e})
    return { viewerId: null, bearerToken: null }
  }
}
