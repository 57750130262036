import { STATE } from 'base/utils/enums'

type Error = {
  error: {
    code: string
  }
}

const ErrorContainer = ({ error }: Error) => {
  return (
    <div className="play-embed">
      <div className="play-embed__container">
        <div className="play-error-text">
          <h2 className="play-embed__text">{STATE[error.code] || 'En ukjent feil har oppstått'}</h2>
          <div className="play_embed__maintext">
            Vi beklager det inntrufne.
            Dersom problemet vedvarer, anbefaler vi å kontakte vårt&nbsp;
            <a href="https://hjelp.play.tv2.no/" target="_blank" rel="noopener noreferrer">kundesenter</a></div>
        </div>
      </div>
      <div className="error-code">{error.code}</div>
    </div>
  )
}

export default ErrorContainer
