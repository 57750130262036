import { ReactPlayer } from 'base/react/Base'
import { getHtmlElement } from 'base/utils'
import { CallbackFunctions } from 'types'

export class BasePlayer {
  /*
    This class acts as the base (parent) class
    with bare minimum features / functions that
    can be extended further by a children wrappers.
  */

  component(
    playerData: unknown,
    contextData: unknown,
    mainContainer?: string | HTMLElement,
    callbacks?: CallbackFunctions
  ) {
    const validContainer = getHtmlElement(mainContainer)

    window.dataLayer = window.dataLayer || []

    ReactPlayer(playerData, contextData, validContainer, callbacks)
  }

}
