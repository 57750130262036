export const STATE = {
  BUFFER_ERROR: 'Kunne ikke lese egenskapen \'buffret\' fra \'SourceBuffer\': Denne SourceBuffer er fjernet fra overordnet mediekilde.',
  SESSION_NOT_AUTHENTICATED: 'Du må være innlogget i TV 2 Play for å se videoen.',
  UNAUTHORIZED: 'Du trenger et abonnement i TV 2 Play for å se videoen.',
  ASSET_PLAYBACK_INVALID_GEO_LOCATION: 'Videoen er ikke tilgjengelig for avspilling i det landet du befinner deg.',
  DEVICE_LIMIT_EXCEEDED: 'Du har nådd maksimalt antall enheter som kan brukes til å se denne videoen.',
  NOT_STARTED: 'Denne strømmen har ikke startet enda.',
  PLAYER_ERROR: 'En feil oppstod i avspiller.',
  CONTENT_PROTECTION_ERROR: 'En feil med kopibeskyttelse har oppstått.',
  SOURCE_NOT_FOUND: 'Videokilden er ikke tilgjengelig.',
  NO_STREAMS_FOUND: 'Videostrømmen er ikke tilgjengelig.',
  SEGMENT_NOT_FOUND: 'Videosegment kunne ikke lastes.',
  AD_ERROR: 'En feil med annonsevisning oppstod.',
  OFFLINE: 'Avspilling av offline innhold feilet.',
  CHROMECAST_ERROR: 'En feil med Chromecast oppstod.',
  ASSET_NOT_PUBLISHED: 'Programmet er ikke tilgjengelig.',
  LIVE_ENDED: 'Live-avspilling er ferdig.',
  NOT_AUTHENTICATED: 'Du må være innlogget i TV 2 Play for å se videoen.'
}