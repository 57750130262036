import useSnowplowTracking from 'hooks/useSnowplowTracking'
import { useEffect } from 'react'
import { withPlayerData } from 'tv2-web-player'
import { CallbackFunctions } from 'types'

type PlayerListenerProps = {
  api?: unknown,
  asset?: unknown,
  player?: unknown,
  container?: unknown,
  config?: unknown,
  data?: unknown,
  error?: unknown,
  callbackFunctions?: CallbackFunctions
}

function PlayerListener({ api, player, container, config, data, error, asset, callbackFunctions }: PlayerListenerProps) {
  const { onApiUpdate, onPlayerUpdate, onContainerUpdate, onConfigUpdate, onDataUpdate, onError, onAssetUpdate, customCallback1 } = callbackFunctions

  useSnowplowTracking(player, data)

  useEffect(() => onApiUpdate && api && onApiUpdate(api), [api, onApiUpdate])
  useEffect(() => onPlayerUpdate && player && onPlayerUpdate(player), [player, onPlayerUpdate])
  useEffect(() => onContainerUpdate && container && onContainerUpdate(container), [container, onContainerUpdate])
  useEffect(() => onConfigUpdate && config && onConfigUpdate(config), [config, onConfigUpdate])
  useEffect(() => onDataUpdate && data && onDataUpdate(data), [data, onDataUpdate])
  useEffect(() => onAssetUpdate && asset && onAssetUpdate(asset), [asset, onAssetUpdate])
  useEffect(() => onError && error && onError(error), [error, onError])

  useEffect(() => {
    if (customCallback1 && asset && typeof player === 'object') {
      if ('state' in player && player['state'] === 'READY') {
        customCallback1({ api, asset, player })
      }
    }
  }, [api, asset, player])

  return null
}

export default withPlayerData(PlayerListener, {
  api: 'api',
  player: 'player',
  container: 'container',
  config: 'config',
  data: 'data',
  error: 'player.error',
  asset: 'data.asset'
})
