import { Player, PlayerContextProvider } from 'tv2-web-player'
import { render } from 'react-dom'

import { getLoginDetails, isInWebView } from 'base/utils'
import { CallbackFunctions } from 'types'

import ErrorContainer from 'base/react/components/ErrorContainer'
import LoginContainer from 'base/react/components/LoginContainer'
import WebViewContainer from 'base/react/components/WebViewContainer'
import PlayerListener from './PlayerListener'
import { IFeatures } from 'tv2-web-player/dist/lib/interfaces'
import { PlayerContextProps } from 'tv2-web-player/dist/lib/context/PlayerContext'

export function ReactPlayer(playerData: unknown, contextDataProps: unknown, mainContainer: HTMLElement, callbackFunctions: CallbackFunctions) {
  let assetId = ''
  let contextData: PlayerContextProps = contextDataProps

  if (
    typeof playerData === 'object' &&
    'assetId' in playerData &&
    typeof playerData['assetId'] === 'string'
  ) {
    assetId = playerData['assetId']
  }

  const COMPONENT_MAP = {
    'NOT_AUTHENTICATED': <LoginContainer assetId={assetId} bearerToken={contextData['bearerToken']} />,
    'UNAUTHORIZED': <LoginContainer assetId={assetId} bearerToken={contextData['bearerToken']} />,
    'WEB_VIEW': <WebViewContainer assetId={assetId} />,
  }

  async function errorOveride(error) {
    if (error.type && error.code && !contextData['bearerToken']) {
      try {
        let Component = COMPONENT_MAP[error.code] || <ErrorContainer error={error} />

        if (isInWebView()) {
          Component = COMPONENT_MAP['WEB_VIEW']
        }

        render(Component, mainContainer)

        const { viewerId, bearerToken } = await getLoginDetails()

        if (viewerId && bearerToken && typeof contextData === 'object') {
          contextData = { ...contextData, viewerId, bearerToken }
          renderPlayer()
        }

      } catch (e) {
        throw new Error(e)
      }
    } else {
      if (error.type && error.code) {
        render(<ErrorContainer error={error} />, mainContainer)
      }
    }
  }

  typeof callbackFunctions === 'undefined'
    ? callbackFunctions = { onError: errorOveride }
    : callbackFunctions = { ...callbackFunctions, onError: errorOveride }

  function renderPlayer() {
    if (typeof contextData !== 'object' || typeof playerData !== 'object') {
      console.error('playerData | contextData is not an object')
      return
    }

    const featureConfig: IFeatures = {
      ...contextData.features,
      upNext: false,
      relatedUpNext: true
    }

    contextData.features = featureConfig

    return (
      render(
        <PlayerContextProvider {...contextData} >
          <Player {...playerData} >
            <PlayerListener callbackFunctions={callbackFunctions} />
          </Player>
        </PlayerContextProvider>,
        mainContainer
      )
    )
  }

  renderPlayer()

}
